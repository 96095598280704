import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import {
  SectionStyles,
  BaseLinkStyles,
  VideoComponent,
  Paragraph,
  UnorderedListStyles,
  BaseButton
} from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const WebinarSummaryContainer = styled.div`
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }

  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
`;

const SmallText = styled.p`
  font-size: 12px;
`;

/* eslint-disable react/no-danger */
const webinarHero = (data) => (
  <SectionStyles backgroundColor={data.backgroundColor}>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h1 className="h2 mb-5">{data.titles}</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-7 mb-4">
          <VideoComponent
            src={data.video.src}
            container={data.video.container}
            aspectRatio={data.video.aspectRatio}
            paddingBottom={data.video.paddingBottom}
            paddingTop={data.video.paddingTop}
            hostType={data.video.hostType}
          />
        </div>
        {/* Speaker details */}
        <div className="col-sm-12 col-md-12 col-lg-5">
          {data.speakers &&
            data.speakers.map((speaker) => (
              <div key={pmUuid()}>
                <h4>{speaker.name}</h4>
                <Paragraph className="strong mb-0">{speaker.company}</Paragraph>
                <Paragraph className="mb-4">{speaker.role}</Paragraph>
                {/* Twitter and LinkedIn Icon */}

                {speaker.linkedin ? (
                  <div className="d-flex flex-row mb-3">
                    <a
                      href={speaker.linkedinURL}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      title={`Follow ${speaker.name} on LinkedIn`}
                    >
                      <svg
                        width="30"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.134 7.363H.537v14.044h4.597zM5.437 3.019C5.407 1.642 4.44.593 2.866.593 1.293.593.265 1.642.265 3.019c0 1.348.998 2.427 2.541 2.427h.03c1.603 0 2.601-1.079 2.601-2.427zM21.714 13.354c0-4.313-2.268-6.32-5.293-6.32-2.44 0-3.534 1.362-4.144 2.319v-1.99H7.68c.06 1.318 0 14.044 0 14.044h4.598v-7.843c0-.42.03-.839.151-1.139.333-.839 1.09-1.707 2.36-1.707 1.664 0 2.329 1.288 2.329 3.175v7.514h4.597v-8.053z"
                          fill="#FF6C37"
                          fillRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                ) : (
                  ''
                )}
                {speaker.twitter ? (
                  <div className="d-flex flex-row mb-3">
                    <a
                      href={speaker.twitterURL}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className="mr-1"
                      title={`Follow ${speaker.name} on Twitter`}
                    >
                      <svg
                        width="30"
                        height="24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M25.955 10.483c-.032.25-.097.492-.162.734-.583 2.175-1.647 4.222-2.986 6.01-3.93 5.163-9.79 7.257-15.664 5.683-2.76-.74-5.257-2.334-7.143-4.542.376.138.784.285 1.17.388 2.31.619 4.747.384 6.952-.654-2.141-.61-3.651-2.68-3.733-5.07a4.73 4.73 0 0 0 2.339.516c-2.237-1.156-3.4-3.873-2.643-6.445l.019-.07a5.09 5.09 0 0 0 2.126 1.31C5.063 6.921 4.682 5.005 5.172 3.175c.26-.967.743-1.873 1.414-2.582 1.684 4.115 4.95 7.21 8.961 8.545.015-.44.094-.863.205-1.278.814-3.038 3.75-4.879 6.575-4.122 1.38.37 2.562 1.352 3.229 2.715a10.32 10.32 0 0 0 3.594-.443c-.719 1.14-1.817 2.03-3.052 2.402 1.055.172 2.1.119 3.152-.08a10.71 10.71 0 0 1-3.295 2.151z"
                          fill="#FF6C37"
                          fillRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}

          {/* Webinar Summary Details */}
          <WebinarSummaryContainer>
            {data.items && (
              <div dangerouslySetInnerHTML={{ __html: data.items.join('') }} />
            )}
            <SmallText className="mt-3">{data.date}</SmallText>
            {/* Extra text such as Register for Part 2 or 3 etc. */}
            {data.text && (
              <div dangerouslySetInnerHTML={{ __html: data.text }} />
            )}
          </WebinarSummaryContainer>
          {data.cta && (
            <BaseButton
              buttonType={data.cta.buttonType}
              as="a"
              src={data.cta.src}
              target={data.cta.target}
              id={data.cta.id}
              gaCategory={data.cta.gaCategory}
              gaLabel={data.cta.gaLabel}
              className={data.cta.type === 'inline' ? 'pl-0' : null}
              dataTest={data.cta.id}
            >
              {data.cta.text}
            </BaseButton>
          )}
        </div>
      </div>
    </div>
  </SectionStyles>
);
/* eslint-enable */

export default webinarHero;
