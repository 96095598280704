import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BaseLink, SectionStyles, Hero } from 'aether-marketing';
import sortByKey from '../../utils/sortByKey';
import DropdownFilter from './DropdownFilter';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const JobsListingWrapper = styled.div`
  background-color: white;
  padding-bottom: 60px;
`;

const Section = styled(SectionStyles)`
  & .floating-header {
    padding-left: 10px;
    padding-bottom: 20px;
    padding-top: 75px;
    @media (min-width: 525px) {
      padding-left: 16px;
    }
  }

  & .floating-header,
  .floating-body {
    z-index: 0; /* to keep under the blurred navbar */
    background-color: ${(props) => props.theme.colors.white};
  }

  @media (min-width: 992px) {
    padding: 5px 0 5px 0;
    & .floating-header {
      padding-left: 0;
    }
    & .floating-header,
    .floating-body {
    }

    & .floating-header {
      float: left;
      width: 25%;
    }

    & .floating-body {
      float: right;
      width: 75%;
      padding-top: 75px;
    }
  }
`;

const TableStyle = styled.table`
  margin-bottom: 0;
  font-size: 1.2rem;

  @media (min-width: 550px) {
    font-size: 1.6rem;
    margin-bottom: 30px;
  }

  .th-style {
    padding: 6px 10px 16px 10px;
    font-size: 1.4rem;

    @media (min-width: 525px) {
      padding: 6px 16px 16px 16px;
    }
  }

  td {
    padding: 10px;

    @media (min-width: 525px) {
      padding: 1.6rem;
    }
  }

  .tr-style {
    border-top: 1px solid #dee2e6;
  }

  &.table-striped tbody tr {
    border: none;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: ${(props) => props.theme.colors.grey_05};
  }

  .apply-cell {
    padding-left: 0;

    @media (min-width: 525px) {
      padding-left: 1.6rem;
    }
  }
`;

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

const friendlySlug = (slug) =>
  `/company/careers/${slug
    .replace(/_([0-9]+)$/, '-$1')
    .split('_')
    .pop()}`;
/* eslint-disable */
function Table(data, key) {
  if (data && data.length > 0) {
    const anchorTag = data[0].department.replace(/\s+/g, '-').toLowerCase();

    return (
      <div key={key}>
        <div className="row">
          <div className="col-lg-12" id={`${anchorTag}`}>
            <div className="sticky-top floating-header">
              <h2 className="mb-1 h3">
                {data && data[0] ? data[0].department : ''}
              </h2>
              <small>
                {`${(data && data.length) || ''} Open Position${
                  (data && data.length) > 1 ? 's' : ''
                }`}
              </small>
            </div>
            <div className="floating-body">
              <TableStyle className="table table-striped">
                <thead>
                  <tr>
                    <th className="border-top-0 th-style">Position</th>
                    <th className="border-top-0 th-style">Location</th>
                    <th className="border-top-0 th-style">Country</th>
                    <th className="border-top-0 th-style"> </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((val) => (
                      <tr key={val.application_url} className="tr-style">
                        <td className="border-0" width="50%">
                          <BaseLink
                            src={`${friendlySlug(val.slug)}/`}
                            onClick={() => {
                              triggerGA(
                                'open-positions-page',
                                `${val.department
                                  .toLowerCase()
                                  .replace(/ /g, '-')}-${val.location.city
                                  .toLowerCase()
                                  .replace(/ /g, '-')}-${val.location.country
                                  .toLowerCase()
                                  .replace(/ /g, '-')}-${val.title
                                  .toLowerCase()
                                  .replace(/ /g, '-')}`
                              );
                            }}
                          >
                            {val.title}
                          </BaseLink>
                        </td>
                        <td className="border-0 td-style" width="15%">
                          {val.location.city || 'Remote'}
                        </td>
                        <td className="border-0 td-style" width="15%">
                          {val.location.country}
                        </td>
                        <td
                          className="border-0 text-right apply-cell text-nowrap"
                          width="20%"
                        >
                          <BaseLink
                            linkType="arrowLink"
                            src={`${friendlySlug(val.slug)}/`}
                            onClick={() => {
                              triggerGA(
                                'open-positions-page',
                                `${val.department
                                  .toLowerCase()
                                  .replace(/ /g, '-')}-${val.location.city
                                  .toLowerCase()
                                  .replace(/ /g, '-')}-${val.location.country
                                  .toLowerCase()
                                  .replace(/ /g, '-')}-${val.title
                                  .toLowerCase()
                                  .replace(/ /g, '-')}`
                              );
                            }}
                          >
                            Apply
                          </BaseLink>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </TableStyle>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}

const getSortedJobs = (d) => {
  if (d && d.forEach) {
    const sortedJobs = {};
    d.forEach((val) => {
      const obj = val;
      if (!obj.department) {
        obj.department = 'Other';
      }
      if (!sortedJobs[obj.department]) {
        sortedJobs[obj.department] = [];
      }
      sortedJobs[obj.department].push(obj);
    });

    Object.keys(sortedJobs).forEach((k) => sortByKey(sortedJobs[k], 'title'));

    return sortedJobs;
  }

  return null;
};
const FilterContainer = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
  border: 1px solid #e6e6e6;

`;
function JobsContainer({ data }) {
  const [payload, setPayload] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');

  useEffect(() => {
    if (data && data.items) {
      const sJobs = getSortedJobs(data.items);
      setPayload({ jobs: sJobs });
    }
  }, [data]);

  if (payload && payload.jobs) {
    const { jobs } = payload;
    const codeMap = {};

    Object.keys(jobs).forEach((key) => {
      jobs[key].forEach((job) => {
        codeMap[`_${job.shortcode}`] = friendlySlug(job.slug);
      });
    });

    if (
      typeof document === 'object' &&
      document.location.search.match('_position=') &&
      data
    ) {
      const code = document.location.search.split('_position=').pop();
      document.location.replace(codeMap[`_${code}`]);
    }

    const allCountries = [
      ...new Set(
        Object.values(jobs)
          .flat()
          .map((job) => job.location.country)
      )
    ];

    const allDepartments = [
      ...new Set(
        Object.values(jobs)
          .flat()
          .map((job) => job.department)
      )
    ];

    const filteredJobs = Object.keys(jobs).reduce((acc, key) => {
      acc[key] = jobs[key].filter(
        (job) =>
          (selectedCountry === '' ||
            job.location.country === selectedCountry) &&
          (selectedDepartment === '' || job.department === selectedDepartment)
      );
      return acc;
    }, {});

    return (
      <Section paddingTop="5px">
        <div className="container">
          <FilterContainer className="row mt-5">
            <div className="col-lg-6">
              <DropdownFilter
                label="Department"
                options={allDepartments}
                selectedOption={selectedDepartment}
                onChange={setSelectedDepartment}
              />
            </div>
            <div className="col-lg-6">
              <DropdownFilter
                label="Country"
                options={allCountries}
                selectedOption={selectedCountry}
                onChange={setSelectedCountry}
              />
            </div>
          </FilterContainer>

          {Object.keys(filteredJobs).map((key) => (
            <React.Fragment key={key}>
              {Table(filteredJobs[key], pmUuid())}
            </React.Fragment>
          ))}
        </div>
      </Section>
    );
  }

  return null;
}

JobsContainer.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired
};

const joblistings = (data) => {
  return (
    <JobsListingWrapper id="open-positions">
      <Hero
        heroLayout={data.heroLayout}
        sideXSideLayout={data.sideXSideLayout}
        title={data.title}
        subtitle={data.subtitle}
        backlink={data.backlink}
        media={data.media}
        divider={data.divider}
      />
      <JobsContainer data={data} />
    </JobsListingWrapper>
  );
};

export default joblistings;
