import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  min-width: 200px;
  // width: auto;
`;

const DropdownButton = styled.div`
  background-color: transparent;
  color: rgb(107, 107, 107);
  font-weight: 600;
  padding: 8px;
  border: none;
  cursor: pointer;
  svg {
    margin-left: 8px;
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f2f2f2;
  border: 1px solid #ededed;
  border-radius: 10px;
  box-shadow: 0 16px 24px -8px rgb(0 0 0 / 24%);
  z-index: 1;
  padding: 16px;
`;

const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const ArrowIcon = styled.svg`
  transition: transform 0.3s;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

function DropdownFilter({ label, options, selectedOption, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const getWidth = () => {
    if (label === 'departments') {
      return '300px';
    }
    if (label === 'country') {
      return '100%';
    }
    return '100%'; // Default width
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} style={{ width: getWidth() }}>
      <DropdownButton onClick={handleToggle}>
        {selectedOption || `Select a ${label.toLowerCase()}`}
        <ArrowIcon
          className="arrow-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="#6b6b6b"
          isOpen={isOpen}
        >
          <g>
            <path d="M10.375,3.219,6,6.719l-4.375-3.5A1,1,0,1,0,.375,4.781l5,4a1,1,0,0,0,1.25,0l5-4a1,1,0,0,0-1.25-1.562Z" />
          </g>
        </ArrowIcon>
      </DropdownButton>
      {isOpen && (
        <DropdownMenu>
          <DropdownItem onClick={() => handleSelect('')}>All</DropdownItem>
          {options.map((option) => (
            <DropdownItem key={pmUuid()} onClick={() => handleSelect(option)}>
              {option}
            </DropdownItem>
          ))}
        </DropdownMenu>
      )}
    </DropdownContainer>
  );
}

DropdownFilter.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DropdownFilter;
