import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const clickTargetStyle = {
  display: 'inline-block',
  padding: '8px 16px',
  fontStyle: 'normal',
  height: '100%',
  width: '100%'
};

export const BaseButton = styled.a`
  align-items: center;
  appearance: none;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius.small};
  cursor: pointer;
  display: inline-block;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  padding: 0 !important;
  user-select: none;
  transition: ${(props) => props.theme.transitions.all};
  text-align: center;
  margin: ${(props) => (props.left ? '0 16px 0 0' : '0')};
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:disabled,
  &.isDisabled {
    color: ${(props) => props.theme.colors.grey_2};
    cursor: not-allowed;
    background-image: none !important;
    background: ${(props) => props.theme.colors.grey_2};
    border-color: transparent;
  }
`;

export const Primary = styled(BaseButton)`
  color: ${(props) => props.theme.colors.white} !important;
  background-color: ${(props) => props.theme.colors.orange_65};

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.orange_55};
  }
`;
export const Secondary = styled(BaseButton)`
  border: 1px solid ${(props) => props.theme.colors.grey_40};
  color: ${(props) => props.theme.colors.grey_60} !important;
  &:hover,
  &:active {
    border: 1px solid ${(props) => props.theme.colors.grey_60};
  }
`;
export const Postcon = styled(BaseButton)`
  color: white !important;
  border: none;
  outline: none;
  position: relative;
  background: linear-gradient(90deg, #ff6c37 0%, #c64dda 100%);
  cursor: pointer;
  z-index: 1;

  &:hover {
    transition: 0.3s ease-in-out;
    background: white;
    box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
    -webkit-box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
    -moz-box-shadow: 0 0 1em -0.15em rgb(226, 207, 244);
  }
  &:active {
    border: 2px solid #ffff;
  }
  &::before {
    content: '';
    position: absolute;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    border-radius: 4px;
    background: #15071f;
    z-index: -1;
  }
  &::after {
    content: '';
    font-size: 16px;
    webkit-background-clip: text;
    color: white !important;
  }
`;
// Helper function for rel attribute in link or button
export function relStringGenerator(target) {
  if (target === 'new-tab-external-company') {
    return 'noopener noreferrer';
  }
  if (target === 'new-tab-external-nofollow') {
    return 'noopener noreferrer nofollow';
  }
  if (target === 'new-tab-postman') {
    return 'noopener';
  }
  return null;
}

// Helper function for target attribute in link or button
export function targetStringGenerator(target) {
  if (
    target === 'new-tab-external-company' ||
    target === 'new-tab-external-nofollow' ||
    target === 'new-tab-postman'
  ) {
    return '_blank';
  }
  return null;
}

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

function Button(props) {
  const {
    buttonType, // defines styles of button
    dataTest,
    href,
    className,
    target,
    gaCategory,
    gaLabel,
    id,
    children,
    ariaLabel,
    event,
    eventProp,
    style
  } = props;
  // Generate onClickHandler
  const onClickHandler = () => {
    if (gaCategory && gaLabel) {
      triggerGA(gaCategory, gaLabel);
    }
    // If we need additional scripts to fire off onClick in the future,
    // We can add a key to the json, check for them, and add the needed logic
    // Below this comment. so it will fire in the onClickHandler fires
    // Important to note, we don't pass in variables via the onClick function,
    // it grabs straight from the props above
  };
  // Below starts returning button based on buttonType.
  // all same functionality, just wraps in different styled component.

  // hollow button with grey outline and grey text
  if (buttonType === 'secondary') {
    return (
      <Secondary
        aria-label={ariaLabel || null}
        href={href}
        data-test={`${dataTest}`}
        data-event={event}
        data-event-prop={eventProp}
        className={className || null}
        id={id || null}
        onClick={onClickHandler}
        rel={relStringGenerator(target)}
        target={targetStringGenerator(target)}
      >
        <i id={id || null} style={clickTargetStyle}>
          {children}
        </i>
      </Secondary>
    );
  }
  if (buttonType === 'postcon') {
    return (
      <Postcon
        aria-label={ariaLabel || null}
        href={href}
        data-test={`${dataTest}`}
        data-event={event}
        data-event-prop={eventProp}
        className={className || null}
        id={id || null}
        onClick={onClickHandler}
        rel={relStringGenerator(target)}
        target={targetStringGenerator(target)}
      >
        <i id={id || null} style={clickTargetStyle}>
          {children}
        </i>
      </Postcon>
    );
  }
  // Defaults to orange primary button
  return (
    <Primary
      aria-label={ariaLabel || null}
      className={className || null}
      href={href || null}
      data-test={`${dataTest}`}
      data-event={event}
      data-event-prop={eventProp}
      id={id || null}
      onClick={onClickHandler}
      rel={relStringGenerator(target)}
      target={targetStringGenerator(target)}
      style={style}
    >
      <i id={id || null} style={{ ...style, ...clickTargetStyle }}>
        {children}
      </i>
    </Primary>
  );
}

Button.propTypes = {
  event: PropTypes.string,
  eventProp: PropTypes.string,
  ariaLabel: PropTypes.string,
  buttonType: PropTypes.string,
  dataTest: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  gaLabel: PropTypes.string,
  gaCategory: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
    cursor: PropTypes.string,
    borderColor: PropTypes.string
  })
};

Button.defaultProps = {
  event: null,
  eventProp: null,
  ariaLabel: null,
  buttonType: 'primary',
  dataTest: '',
  className: '',
  href: null,
  target: 'same-tab',
  id: '',
  gaLabel: null,
  gaCategory: null,
  style: null
};

export default Button;
